import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/loading" element={<LoadingPage />} />
    </Routes>
  );
}

function SearchPage() {
  const [query, setQuery] = useState('');
  const [files, setFiles] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post('http://localhost:3001/api/search', { query });
      setFiles(response.data.files);
      setNextPageToken(response.data.nextPageToken);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setLoading(true);
    try {
      const response = await axios.post('http://localhost:3001/api/search', { query, pageToken: nextPageToken });
      setFiles([...files, ...response.data.files]);
      setNextPageToken(response.data.nextPageToken);
    } catch (error) {
      console.error('Error fetching more results:', error);
    }
    setLoading(false);
  };

  const formatSize = (size) => {
    if (size) {
      return (size / (1024 ** 3)).toFixed(2) + ' GB';
    }
    return 'N/A';
  };

  const handleViewClick = (fileId) => {
    const driveLink = `https://drive.google.com/file/d/${fileId}/view`;
    navigate('/loading', { state: { url: driveLink } });
  };

  return (
    <div className="app">
      <header>
        <h1>Google Drive Search</h1>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search files..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
          <button onClick={handleSearch} disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>
      </header>

      <div className="results-container">
        {files.length > 0 ? (
          files.map((file) => (
            <div key={file.id} className="file-card">
              <div className="file-info">
                <h3>{file.name}</h3>
                <p>Size: {formatSize(file.size)}</p>
                <button onClick={() => handleViewClick(file.id)}>View</button>
              </div>
            </div>
          ))
        ) : (
          <p>No files found. Try searching for something.</p>
        )}
      </div>

      {nextPageToken && (
        <button className="load-more-btn" onClick={loadMore} disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  );
}

function LoadingPage() {
  const navigate = useNavigate();
  const { url } = useLocation().state || {};

  useEffect(() => {
    if (url) {
      setTimeout(() => {
        window.location.href = url;
      }, 5000); // Wait for 5 seconds before redirecting
    } else {
      navigate('/'); // Redirect to home if URL is not available
    }
  }, [url, navigate]);

  return (
    <div className="loading-page">
      <div className="loader"></div>
      <h2>Loading...</h2>
      <p>Please wait while we redirect you to the file.</p>
    </div>
  );
}

export default App;
