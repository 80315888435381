import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './LoadingPage.css'; // Make sure this is the correct path

function LoadingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { url } = location.state || {}; // Safely access the state object

  useEffect(() => {
    if (url) {
      console.log("Redirecting to:", url);
      setTimeout(() => {
        window.location.href = url; // Redirect after 5 seconds
      }, 5000);
    } else {
      console.log("URL is not available, navigating back to home.");
      navigate('/'); // If no URL is present, navigate to the home page
    }
  }, [url, navigate]);

  return (
    <div className="loading-page">
      <div className="loader"></div>
      <h2>Loading...</h2>
      <p>Please wait while we redirect you to the file.</p>
    </div>
  );
}

export default LoadingPage; // Add the default export here
